import sarah from "./images/sarah.webp";

export const data = [
  {
    id: 1,
    author: "John D.",
    title: "Love the results!",
    content:
      "I absolutely love the results I've achieved with this GLP-1 injection in just a few weeks. I've noticed a significant difference in my weight and overall energy levels. The injections are easy to use and integrate seamlessly into my daily routine. I couldn't be happier with the progress I've made thanks to this amazing product!",
    rating: 5,
    image: "reviews/john.webp",
  },
  {
    id: 2,
    author: "Sarah M.",
    title: "Quick and Painless!!",
    content:
      "Using this GLP-1 injection has been a quick and painless experience. I hardly felt the needles, and the whole process takes just a few minutes each day. It's been a fantastic addition to my weight loss routine, and I've already started seeing results. If you're looking for a hassle-free way to lose weight, this is it!",
    rating: 5,
    image: "reviews/sarah.webp",
  },
  {
    id: 3,
    author: "Patricia R.",
    title: "Easy Process!",
    content:
      "I was nervous about starting a weight loss injection, but the process couldn't have been easier! The instructions were straightforward, and administering the injection at home was a breeze. I appreciated the simplicity and convenience of this treatment. Highly recommend for anyone looking for an effective weight loss solution.",
    rating: 5,
    image: "reviews/patricia.webp",
  },
];
