"use client";

import { Card, Text, Heading, Flex, Box, Avatar } from "@radix-ui/themes";
import { Theme } from "@radix-ui/themes";
import { StarFilledIcon } from "@radix-ui/react-icons";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { data } from "@/components/_sections/reviews/data";
import styles from "./reviews.module.css";
import "@/app/theme.css";

export default function Reviews() {
  const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay()]);

  return (
    <Theme
      accentColor="teal"
      panelBackground="solid"
      radius="large"
      appearance="light"
      style={{ minHeight: "unset !important" }}
    >
      <div className={styles.embla} ref={emblaRef}>
        <div className={styles.emblaContainer}>
          {data.map((review) => (
            <div className={styles.emblaSlide} style={{}} key={review.id}>
              <Card
                size="3"
                style={{
                  width: "100%",
                  boxShadow: "var(--shadow-5)",
                  gap: "16px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <Avatar
                    src={review.image}
                    fallback={review.author}
                    size={"5"}
                  />
                  <Box>
                    <Heading
                      as="h3"
                      size="5"
                      style={{ color: "var(--color-green)" }}
                    >
                      <em>{review.title}</em>
                    </Heading>
                    <em>~ {review.author}</em>
                  </Box>
                </Box>
                <Box py={"2"}>
                  <Text size="2">“{review.content}”</Text>
                </Box>
                <Flex justify={"end"}>
                  {[...Array(review.rating)].map((_, i) => (
                    <StarFilledIcon
                      key={i}
                      width={"24px"}
                      height={"24px"}
                      style={{
                        color: "gold",
                      }}
                    />
                  ))}
                </Flex>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Theme>
  );
}
